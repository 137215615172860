<template>
  <v-dialog
    v-model="isOpen"
    width="400"
    persistent
  >
    <v-card>
      <v-card-title class="subheading">
        Upload APNS Cert
      </v-card-title>
      <v-card-text>
        <v-container>
            <v-form ref="form">
            <v-layout row>
              <v-flex>
                Warning: Uploading an invalid certificate WILL break functionality on iOS devices.
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex>
                <v-select
                  :items="selectData"
                  item-text="text"
                  item-value="value"
                  v-model="selectedType"
                  label="Select Certificate Type"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex>
                <input
                  type="file"
                  @change="onFilePicked"
                  ref="fileInput"
                >
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex>
                <v-text-field
                  v-bind="fields.apnsCertificatePassword"
                  v-model="password"
                  :append-icon="passVis ? 'visibility' : 'visibility_off'"
                  :type="passVis ? 'password' : 'text'"
                  @click:append="() => (passVis = !passVis)"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <BaseButton
          text="Close"
          @clicked="clicked(false)"
        />
        <BaseButton
          text="Save"
          color="accent"
          @clicked="clicked(true)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fields } from '@/components/config/applications';
import filePicker from '@/components/mixins/filePicker';

export default {
  name: 'ApnsDialog',
  mixins: [filePicker],
  props: {
    open: Boolean,
    isProd: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    fields,
    isOpen: false,
    selectedType: null,
    apnsPassword: {
      label: 'Secret',
    },
    selectData: [
      {
        value: 'prod',
        text: 'Production APNS Certificate',
      },
      {
        value: 'test',
        text: 'Test APNS Certificate',
      },
    ],
    password: null,
    passVis: true,
  }),
  watch: {
    open(val) {
      const input = this.$refs.fileInput;
      input.type = 'file';
      this.fileData = null;
      this.password = null;
      this.isOpen = val;
    },
  },
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  methods: {
    clicked(accepted) {
      if (accepted && this.$refs.form.validate()) {
        this.$emit('accepted', {
          file: this.fileData[0],
          password: this.password,
          type: this.selectedType,
        });
      }
      this.clearModal();
      this.isOpen = false;
      this.$emit('closed');
    },
    clearModal() {
      const input = this.$refs.fileInput;
      input.value = null;
      this.selectedType = null;
      this.password = null;
      this.fileData = null;
    },
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
    }
  },
};
</script>
